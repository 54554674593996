import Vue from 'vue';
import Vuex from 'vuex';
import io from 'socket.io-client';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    socket: null,
    host: false,
    gameInProgress: false
  },
  mutations: {},
  actions: {
    setSocket({ state }, pseudo) {
      state.socket = io(process.env.VUE_APP_BASE_URL)
      state.socket.emit('ADD_PLAYER', { pseudo })
    },
    setHost({ state }) {
      state.host = true
    },
    setGameInProgress({ state }, newState) {
      state.gameInProgress = newState
    },
  },
  modules: {},
});
