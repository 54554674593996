import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  { path: '/', alias: '/login', component: () => import('../views/Login.vue') },
  { path: '/lobby', component: () => import('../views/Lobby.vue') },
  { path: '/game', component: () => import('../views/Game.vue') },
  { path: '/cgu', component: () => import('../views/CGU.vue') },
  { path: '/privacy-policy', component: () => import('../views/PrivacyPolicy.vue') },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
